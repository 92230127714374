
import pickBy from "lodash/pickBy"
import { mapState } from "vuex"

export default {
  name: "LandingDemoSocials",
  computed: {
    ...mapState("app", ["event"]),
    links () {
      return pickBy(this.event?.links, Boolean)
    },
  },
}
