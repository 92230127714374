// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gHQGB1qb{margin:0;position:relative}.gHQGB1qb:before{background-color:#212121;bottom:0;content:\"\";left:-16px;position:absolute;right:-16px;top:0}@media(min-width:947.98px){.gHQGB1qb:before{left:-50vw;right:-50vw}}.Sa5MMfA-{font-size:13px;padding:32px 0 0;position:relative;width:100%}@media(min-width:947.98px){.Sa5MMfA-{display:flex;flex-wrap:wrap;padding:90px 0 34px}}.lVMX1Yb8{padding:0 0 24px;text-align:center;width:100%}@media(min-width:947.98px){.lVMX1Yb8{align-items:center;display:flex;justify-content:space-between;padding:0 0 56px;text-align:left}.lVMX1Yb8:last-of-type{flex-direction:row-reverse}.eOnUiYaC{width:33%}}.eOnUiYaC p{margin:0;padding:0 0 12px}.xk6E\\+W6c{color:var(--color-text);display:block;font-size:24px;font-weight:700;padding:0 0 16px}@media(min-width:947.98px){.xk6E\\+W6c{font-size:32px;padding:0 0 20px}}.svcYWHPJ{color:var(--gradient-from);display:block;font-size:20px;font-weight:700;padding:0 0 20px}@media(min-width:947.98px){.svcYWHPJ{font-size:26px;padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"footer": "gHQGB1qb",
	"wrapper": "Sa5MMfA-",
	"row": "lVMX1Yb8",
	"cell": "eOnUiYaC",
	"phone": "xk6E+W6c",
	"email": "svcYWHPJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
